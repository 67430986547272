import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage"; // Add storage import

firebase.initializeApp({
  apiKey: "AIzaSyAft59SFKicyg9ynhULLJw_N-1Xl29PCqQ",
  authDomain: "assetpoint-b0df5.firebaseapp.com",
  projectId: "assetpoint-b0df5",
  storageBucket: "assetpoint-b0df5.appspot.com",
  messagingSenderId: "832966261966",
  appId: "1:832966261966:web:ad8ae0340a16fb7f5ae49f",
  measurementId: "G-48L9CXCY71",
});

const db = firebase.firestore();
const storage = firebase.storage(); // Initialize storage
const FieldValue = firebase.firestore.FieldValue;

export { db, firebase, storage, FieldValue };
