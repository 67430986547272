import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../Styles/footer.css"; // Import CSS file for styling
import logo from "../Images/Logo2.png"; // Import your logo image
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-row">
          <div className="footer-column">
            <div className="logo-container2">
              <img src={logo} alt="Company Logo" />
            </div>
          </div>
          <div className="footer-column">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="">
                  <ScrollLink
                    to="form"
                    smooth={true}
                    duration={500}
                    //onClick={toggleMenu}
                  >
                    Form
                  </ScrollLink>
                </a>
              </li>

              <li>
                <a href="">
                  <ScrollLink
                    to="about"
                    smooth={true}
                    duration={500}
                    //onClick={toggleMenu}
                  >
                    About Us
                  </ScrollLink>
                </a>
              </li>
              <li>
                <a href="">
                  <ScrollLink
                    to="offer"
                    smooth={true}
                    duration={500}
                    //onClick={toggleMenu}
                  >
                    Offers
                  </ScrollLink>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Contact Us</h3>
            <p>No: 289, Waikiki complex</p>
            <p>basement,shop no: B-18</p>
            <p>purasaiwalkam high road</p>
            <p>purasaiwalkam, Chennai -600 007</p>
          </div>

          <div className="footer-column">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/people/Loan-With-Offer/61561233343267/?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.instagram.com/invites/contact/?igsh=1oxsx3n3hsv8h&utm_content=v1eui7i"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://youtube.com/@assetpointhomes?si=-1o6DO1GkqTF5KPV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
            <h3>FeedBack</h3>

            <p>Email: service@loanwithoffer.com</p>
            <p>Phone: +91 7449000445</p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p style={{ color: "white" }}>
          &copy; 2024 Loan With Offer. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
