import React, { useState } from "react";
import "../Styles/emicalculator.css";

function EMICalculator() {
  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTenure, setLoanTenure] = useState("");
  const [emi, setEmi] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [error, setError] = useState("");

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };

  const calculateEMI = (e) => {
    e.preventDefault();
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 12 / 100;
    const tenure = parseInt(loanTenure) * 12;

    if (!principal || !rate || !tenure) {
      setError("Please fill in all fields.");
      setEmi(null);
      setTotalInterest(null);
      setTotalAmount(null);
      return;
    }

    if (principal <= 0 || rate <= 0 || tenure <= 0) {
      setError("Please enter positive values.");
      setEmi(null);
      setTotalInterest(null);
      setTotalAmount(null);
      return;
    }

    const emi =
      (principal * rate * Math.pow(1 + rate, tenure)) /
      (Math.pow(1 + rate, tenure) - 1);
    const totalAmountPayable = emi * tenure;
    const totalInterestPayable = totalAmountPayable - principal;

    setEmi(emi.toFixed(2));
    setTotalInterest(totalInterestPayable.toFixed(2));
    setTotalAmount(totalAmountPayable.toFixed(2));
    setError("");
  };

  const resetForm = () => {
    setLoanAmount("");
    setInterestRate("");
    setLoanTenure("");
    setEmi(null);
    setTotalInterest(null);
    setTotalAmount(null);
    setError("");
  };

  return (
    <div className="emi-container">
      <h1 className="emi-title">EMI Calculator</h1>
      <form className="emi-form" onSubmit={calculateEMI}>
        <div className="form-group">
          <label htmlFor="loanAmount" style={{ color: "black" }}>
            Loan Amount (INR)
          </label>
          <input
            type="number"
            id="loanAmount"
            value={loanAmount}
            onChange={(e) => setLoanAmount(e.target.value)}
            placeholder="Enter loan amount"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="interestRate" style={{ color: "black" }}>
            Interest Rate (%)
          </label>
          <input
            type="number"
            id="interestRate"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="Enter interest rate"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="loanTenure" style={{ color: "black" }}>
            Loan Tenure (Years)
          </label>
          <input
            type="number"
            id="loanTenure"
            value={loanTenure}
            onChange={(e) => setLoanTenure(e.target.value)}
            placeholder="Enter loan tenure in years"
            required
          />
        </div>
        <button type="submit" className="calculate-button">
          Calculate EMI
        </button>
        <button type="button" onClick={resetForm} className="reset-button">
          Reset
        </button>
      </form>
      {error && <div className="error">{error}</div>}
      {emi !== null && (
        <div className="emi-result">
          <h2>
            Monthly EMI: <span>₹{formatNumber(emi)}</span>
          </h2>
          <h2>
            Principal amount:{" "}
            <span>₹{formatNumber(parseFloat(loanAmount).toFixed(2))}</span>
          </h2>
          <h2>
            Total interest:{" "}
            <span>₹{formatNumber(parseFloat(totalInterest).toFixed(2))}</span>
          </h2>
          <h2>
            Total amount:{" "}
            <span>₹{formatNumber(parseFloat(totalAmount).toFixed(2))}</span>
          </h2>
        </div>
      )}
    </div>
  );
}

export default EMICalculator;
