import React, { useState, useEffect } from "react";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import "../Styles/header.css";
import Logo from "../Images/Logo2.png";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCibilButton, setShowCibilButton] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchCibilScore = async () => {
      try {
        const docRef = doc(db, "CibilScore", "liBosDn56HY8n0v6UJ5I");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setShowCibilButton(data.show || false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching CibilScore: ", error);
      }
    };

    fetchCibilScore();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <header className={`header ${visible ? "visible" : "hidden"}`}>
      <div className="logo">
        <RouterLink
          to="/"
          className="logo"
          onClick={scrollToTop}
          onMouseEnter={() => (document.body.style.cursor = "pointer")}
          onMouseLeave={() => (document.body.style.cursor = "default")}
        >
          <img src={Logo} alt="Logo" />
        </RouterLink>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
      </div>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
        <ul className="nav-menu">
          <li
            onMouseEnter={() => (document.body.style.cursor = "pointer")}
            onMouseLeave={() => (document.body.style.cursor = "default")}
          >
            <ScrollLink
              to="about"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              <RouterLink to="/about-us" onClick={toggleMenu}>
                About Us
              </RouterLink>
            </ScrollLink>
          </li>
          <li
            onMouseEnter={() => (document.body.style.cursor = "pointer")}
            onMouseLeave={() => (document.body.style.cursor = "default")}
          >
            <ScrollLink
              to="form"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              <RouterLink to="/form" onClick={toggleMenu}>
                Form
              </RouterLink>
            </ScrollLink>
          </li>
          {showCibilButton && (
            <li
              onMouseEnter={() => (document.body.style.cursor = "pointer")}
              onMouseLeave={() => (document.body.style.cursor = "default")}
            >
              <ScrollLink
                to="cibil"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                <RouterLink to="/cibil-score" onClick={toggleMenu}>
                  Cibil Score
                </RouterLink>
              </ScrollLink>
            </li>
          )}
          <li
            onMouseEnter={() => (document.body.style.cursor = "pointer")}
            onMouseLeave={() => (document.body.style.cursor = "default")}
          >
            <ScrollLink
              to="offer"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              <RouterLink to="/offers" onClick={toggleMenu}>
                Offers
              </RouterLink>
            </ScrollLink>
          </li>
          <li
            onMouseEnter={() => (document.body.style.cursor = "pointer")}
            onMouseLeave={() => (document.body.style.cursor = "default")}
          >
            <ScrollLink
              to="calculator"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              <RouterLink to="/calculator" onClick={toggleMenu}>
                EMI Calculator
              </RouterLink>
            </ScrollLink>
          </li>
          <li
            onMouseEnter={() => (document.body.style.cursor = "pointer")}
            onMouseLeave={() => (document.body.style.cursor = "default")}
          >
            <ScrollLink
              to="footer"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              <RouterLink to="/contact-us" onClick={toggleMenu}>
                Contact Us
              </RouterLink>
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
