import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path according to your project structure
import Header from "./Header";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import Form from "./Form";
import Offer from "./Offer";
import EMICalculator from "./EMICalculator";
import "../Styles/layout.css";
import Testimonial from "./Testimonials";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { getDocs, collection } from "firebase/firestore";

function App() {
  const [showCibilButton, setShowCibilButton] = useState(false);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchCibilScore = async () => {
      try {
        const docRef = doc(db, "CibilScore", "liBosDn56HY8n0v6UJ5I");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.show) {
            setShowCibilButton(true);
          } else {
            setShowCibilButton(false);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching CibilScore: ", error);
      }
    };

    const fetchTestimonal = async () => {
      try {
        // Fetch CibilScore document
        const docRef = collection(db, "Testimonials");
        const snapshot = await getDocs(docRef);

        // Map through documents and set state
        const testimonialsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTestimonials(testimonialsData);
      } catch (error) {
        console.error("Error fetching Testimonials: ", error);
      }
    };
    fetchTestimonal();
    fetchCibilScore();
  }, []);

  return (
    <div>
      <Header />
      <Banner />
      <section id="form">
        <Form />
      </section>
      <section id="offer">
        <Offer />
      </section>
      {showCibilButton && (
        <section id="cibil">
          <div className="button-container">
            <button className="cibil-button">
              Get your CIBIL score (Free)
            </button>
          </div>
        </section>
      )}
      <section id="calculator">
        <EMICalculator />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      {testimonials.length > 0 && (
        <section id="testimonial">
          <Testimonial testimonials={testimonials} />
        </section>
      )}
      <section id="footer">
        <Footer />
      </section>
      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/+917449000445"
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="whatsapp-icon"
          size="3x"
        />
      </a>
    </div>
  );
}

export default App;
