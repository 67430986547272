import React, { useState, useEffect, useRef } from "react";
import { db } from "../firebase"; // Import your Firebase configuration
import "../Styles/testimonial.css"; // Import CSS file for styling
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [index, setIndex] = useState(0);
  const testimonialContainerRef = useRef(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonialSnapshot = await db.collection("Testimonials").get();
        const testimonialData = testimonialSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTestimonials(testimonialData);
      } catch (error) {
        console.error("Error fetching testimonials: ", error);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000); // Change testimonial every 5 seconds

    return () => clearInterval(interval);
  }, [testimonials]);

  useEffect(() => {
    if (testimonialContainerRef.current) {
      testimonialContainerRef.current.scrollTo({
        left: testimonialContainerRef.current.offsetWidth * 0.25 * index, // 25% width for each card
        behavior: "smooth",
      });
    }
  }, [index]);

  return (
    <div className="testimonial-container">
      <h1>Testimonials</h1>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={true}
        containerClass="carousel-container"
        customTransition="all .5"
        dotListClass=""
        draggable
        focusOnSelect={true}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 764,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial">
            <p>{testimonial.content}</p>
            <div className="testimonial-rating">
              {/* Generate dynamic stars based on rating */}
              {Array.from({ length: testimonial.rating }, (_, index) => (
                <span key={index} className="star">
                  &#9733;
                </span>
              ))}
            </div>
            <div className="testimonial-author">
              <span className="name">{testimonial.name}</span>
              <span className="position">{testimonial.position}</span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonial;
