import React from "react";
import "../Styles/aboutus.css";

function AboutUs() {
  return (
    <div className="about-us">
      <h1>About Us</h1>
      <h2>
        Welcome to Loan with offer, your trusted partner in financial solutions
      </h2>
      <p>
        Since our founding in 2011, we have been dedicated to helping
        individuals and businesses achieve their financial goals through a wide
        range of loan products and personalized services. Our mission is to
        empower our clients with the financial resources they need to succeed.
        We believe in providing accessible, transparent, and efficient loan
        services that cater to the unique needs of each customer. We are
        committed to building lasting relationships with our clients based on
        trust, reliability, and mutual respect. At Asset Point, your financial
        well-being is our priority, and we are here to support you every step of
        the way. Types of loans <b>Home loan</b>, <b>Land loan</b>,{" "}
        <b>Self construction</b>, <b>Balance Transfer</b>,{" "}
        <b>Loan against property</b>, <b>Business loan</b>, <b>Personal loan</b>
        , <b>Machinery loan</b> Etc...
      </p>
    </div>
  );
}

export default AboutUs;
