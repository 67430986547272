import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import the Firebase firestore object
import { Spin } from "antd";
import "../Styles/banner.css";

const HeroSection = () => {
  const [images, setImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = db
          .collection("BannerWebsite")
          .doc("0DWNJTlxhpN0bE6YtNMm");
        const docSnapshot = await docRef.get();
        const websiteImages = docSnapshot.exists
          ? docSnapshot.data().OfferBannerWebsite || []
          : [];

        const mobileImagesRef = db
          .collection("BannerWebsite")
          .doc("0DWNJTlxhpN0bE6YtNMm");
        const mobileSnapshot = await mobileImagesRef.get();
        const mobileImages = mobileSnapshot.exists
          ? mobileSnapshot.data().OfferBannerMobile || []
          : [];
        console.log(websiteImages);
        setImages(websiteImages);
        setMobileImages(mobileImages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 4 seconds

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleResize); // Remove event listener on component unmount
    };
  }, [images.length]); // Add images.length to the dependency array to ensure useEffect runs when images changes

  const currentImage = isMobile
    ? mobileImages[currentImageIndex]
    : images[currentImageIndex];

  return (
    <div className="container35">
      <div className="elementor elementor-9162">
        <div className="elementor-element elementor-element-ce8a110">
          <div className="elementor-background-slideshow__slide__image image1">
            <div className="hero-carousel">
              {loading ? (
                ""
              ) : currentImage && currentImage.link ? (
                <a
                  href={currentImage.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={currentImage?.image}
                    alt={`Image ${currentImageIndex + 1}`}
                  />
                </a>
              ) : (
                <img
                  src={currentImage?.image}
                  alt={`Image ${currentImageIndex + 1}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
