import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Adjust the path according to your project structure
import "../Styles/form.css";
import { message, Modal, Row, Col, Button, Select } from "antd";
import { collection, query, where, getDocs } from "firebase/firestore";
import moment from "moment";
const { Option } = Select;

function Form() {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    mobile: "",
    email: "",
    employment: "",
    employmentType: "",
    netIncome: "",
    netProfit: "",
    loanType: "",
    loanAmount: 0,
    bankName: [],
    loanDays: "",
    emi: "",
    cibilIssues: "",
    callTime: "",
    city: "",
    status: "Pending",
    acceptTerms: false,
    notes: "",
  });
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false); // State to control the visibility of success message

  const [loanTypes, setLoanTypes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [cities, setCities] = useState([]);
  const [employmentSubcategories, setEmploymentSubcategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchLoanTypes();
    fetchCities();
  }, []);

  const fetchLoanTypes = async () => {
    try {
      const loanTypeQuery = query(
        collection(db, "LoanType"),
        where("status", "==", true)
      );
      const querySnapshot = await getDocs(loanTypeQuery);
      const loanTypeData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoanTypes(loanTypeData);
    } catch (error) {
      console.error("Error fetching loan types: ", error);
      message.error("Failed to fetch loan types");
    }
  };

  const isValidMobileNumber = (mobileNumber) => {
    // Regular expression to match Indian mobile numbers (10 digits starting with 6, 7, 8, or 9)
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileNumber);
  };
  const fetchCities = async () => {
    try {
      const cityQuery = query(
        collection(db, "City"),
        where("status", "==", true)
      );
      const querySnapshot = await getDocs(cityQuery);
      const cityData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCities(cityData);
    } catch (error) {
      console.error("Error fetching cities: ", error);
      message.error("Failed to fetch cities");
    }
  };

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "loanAmount" && type === "text") {
      const numericValue = parseInt(value.replace(/[^0-9]/g, ""), 10);
      setFormData({
        ...formData,
        [name]: isNaN(numericValue) ? 0 : numericValue,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "employment") {
      const subcategories =
        value === "salary"
          ? ["Government", "Private limited", "MNC", "NRI", "Others"]
          : [
              "Business",
              "Private limited",
              "Partnership",
              "Proprietorship",
              "Others",
            ];
      setEmploymentSubcategories(subcategories);
      setFormData({
        ...formData,
        employment: value,
        employmentType: "", // Reset employmentType when employment changes
        netIncome: "", // Reset netIncome when employment changes
        netProfit: "", // Reset netProfit when employment changes
      });
    }

    if (name === "loanType") {
      const selectedLoanType = loanTypes.find(
        (loanType) => loanType.name === value
      );
      if (selectedLoanType) {
        const bankNames = selectedLoanType.banks || [];
        const validBanks = await fetchValidBanks(bankNames);
        setBanks(validBanks);
      } else {
        setBanks([]);
      }
    }
  };

  const fetchValidBanks = async (bankNames) => {
    try {
      const bankQuery = query(
        collection(db, "Bank"),
        where("name", "in", bankNames),
        where("status", "==", true)
      );
      const querySnapshot = await getDocs(bankQuery);
      const validBankData = querySnapshot.docs.map((doc) => doc.data());
      console.log(validBankData);
      return validBankData;
    } catch (error) {
      console.error("Error fetching banks: ", error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    if (formData.bankName.length < 1) {
      console.log("come");
    }
    console.log(formData.bankName.length < 1);
    e.preventDefault();
    if (!isValidMobileNumber(formData.mobile)) {
      // Display an error message or handle the error as needed
      message.error("Please enter a valid mobile number.");
      return;
    }
    if (formData.bankName.length < 1) {
      // Display an error message or handle the error as needed
      message.error("Please select a banks.");
      return;
    }

    // Validate age
    if (formData.age < 21 || formData.age > 90) {
      message.error("Age must be between 21 and 90.");
      return;
    }
    if (parseInt(formData.loanAmount) <= 50000) {
      message.error("Loan required amount greater than 50000");
      return;
    }

    const currentDate = new Date();

    const formDataWithTimestamp = {
      ...formData,
      createdAt: currentDate,
    };

    try {
      const docRef = await db.collection("Enquiry").add(formDataWithTimestamp);
      console.log("Form added with ID: ", docRef.id);
      setFormData({
        name: "",
        age: "",
        mobile: "",
        email: "",
        employment: "",
        employmentType: "",
        netIncome: "",
        netProfit: "",
        loanType: "",
        loanAmount: 0,
        bankName: [],
        loanDays: "",
        emi: "",
        cibilIssues: "",
        callTime: "",
        city: "",
        status: "Pending",
        notes: "",
        acceptTerms: false,
      });
      setBanks([]);
      setEmploymentSubcategories([]);
      // message.success("Thank You!");
      setIsSuccessMessageVisible(true);
      setTimeout(() => {
        setIsSuccessMessageVisible(false);
      }, 8000);
    } catch (error) {
      console.error("Error adding enquiry: ", error);
      message.error("Error adding enquiry. Please try again.");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCurrentDateTime = () => {
    return moment().format("YYYY-MM-DDTHH:mm");
  };

  const handleBankChange = (value) => {
    if (value.length <= 5) {
      setFormData({ ...formData, bankName: value });
    } else {
      message.error("You can only select up to 5 banks.");
    }
  };

  const formatWithCommas = (number) => {
    const x = number.toString();
    const lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    return otherNumbers !== ""
      ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;
  };

  return (
    <div className="form-container">
      <h1>Loan Enquiry Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="age">Age*</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile Number*</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Mail ID</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="employment">Salary or Self Employed*</label>
          <div className="radio-group">
            <input
              type="radio"
              id="salary"
              name="employment"
              value="salary"
              checked={formData.employment === "salary"}
              onChange={handleChange}
              required
              className="custom-radio"
            />
            <label htmlFor="salary">Salary</label>
            <input
              type="radio"
              id="self-employed"
              name="employment"
              value="self-employed"
              checked={formData.employment === "self-employed"}
              onChange={handleChange}
              required
              className="custom-radio"
            />
            <label htmlFor="self-employed">Self Employed (Business)</label>
          </div>
        </div>

        {employmentSubcategories.length > 0 && (
          <div className="form-group">
            <label htmlFor="employmentType">Employment Type*</label>
            <select
              id="employmentType"
              name="employmentType"
              value={formData.employmentType}
              onChange={handleChange}
              required
            >
              <option value="">Select Type</option>
              {employmentSubcategories.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        )}
        {formData.employment === "salary" && (
          <div className="form-group">
            <label htmlFor="netIncome">Net Monthly Income*</label>
            <input
              type="number"
              id="netIncome"
              name="netIncome"
              value={formData.netIncome}
              onChange={handleChange}
              required
            />
          </div>
        )}
        {formData.employment === "self-employed" && (
          <div className="form-group">
            <label htmlFor="netProfit">Last Year's Net Profit*</label>
            <input
              type="number"
              id="netProfit"
              name="netProfit"
              value={formData.netProfit}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="emi">
            Total EMIs you currently pay per month (if any){" "}
          </label>
          {/* <span style={{ color: "#c1dadf" }}>{formData.loanAmount}</span> */}
          <input
            type="text"
            id="emi"
            name="emi"
            value={formData.emi}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="loanType">Loan Type*</label>
          <select
            id="loanType"
            name="loanType"
            value={formData.loanType}
            onChange={handleChange}
            required
          >
            <option value="">Select Loan Type</option>

            {loanTypes
              .slice() // Create a copy to avoid mutating the original array
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
              .map((loanType) => (
                <option key={loanType.id} value={loanType.name}>
                  {loanType.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="loanAmount">Required Loan Amount*</label>
          <input
            type="range"
            id="loanAmount"
            name="loanAmount"
            min="100000"
            max="30000000"
            step="50000"
            value={formData.loanAmount}
            onChange={handleChange}
            required
          />
          {/* <span style={{ color: "#c1dadf" }}>{formData.loanAmount}</span> */}
          <input
            type="text"
            id="loanAmount"
            name="loanAmount"
            value={`Rs. ${formatWithCommas(formData.loanAmount)}`}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="bankName">
            {" "}
            Bank name ( you can choose max 5 banks )*
          </label>
          <Select
            id="bankName"
            name="bankName"
            value={formData.bankName}
            onChange={handleBankChange}
            mode="multiple"
            placeholder="Select Bank"
            className="custom-select"
            style={{ width: "100%", color: "black", textAlign: "left" }}
          >
            {/* {banks.map((bank, index) => (
              <Option key={index} value={bank.name}>
                {bank.name}
              </Option>
            ))} */}
            {banks
              .slice() // Create a copy to avoid mutating the original array
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
              .map((bank, index) => (
                <Option key={index} value={bank.name}>
                  {bank.name}
                </Option>
              ))}
          </Select>
        </div>

        <div className="form-group">
          <label htmlFor="loanDays">How Soon do You Require Loan?</label>
          <select
            id="loanDays"
            name="loanDays"
            value={formData.loanDays}
            onChange={handleChange}
          >
            <option value="">Select Option</option>
            <option value="within-10-days">Within 10 days</option>
            <option value="10-30-days">Within 10 to 30 days</option>
            <option value="30-60-days">Within 30 to 60 days</option>
            <option value="after-60-days">After 60 days</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="cibilIssues">Any CIBIL Issues?</label>
          <div className="radio-group">
            <input
              type="radio"
              id="cibilIssuesYes"
              name="cibilIssues"
              value="yes"
              checked={formData.cibilIssues === "yes"}
              onChange={handleChange}
              className="custom-radio"
            />
            <label htmlFor="cibilIssuesYes">Yes</label>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <input
              type="radio"
              id="cibilIssuesNo"
              name="cibilIssues"
              value="no"
              checked={formData.cibilIssues === "no"}
              onChange={handleChange}
              className="custom-radio"
            />
            <label htmlFor="cibilIssuesNo">No</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="callTime">Preferred Time to Call</label>
          <input
            type="datetime-local"
            id="callTime"
            name="callTime"
            value={formData.callTime}
            onChange={handleChange}
            min={getCurrentDateTime()}
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City*</label>
          <select
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          >
            <option value="">Select City</option>
            {/* {cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))} */}
            {cities
              .slice() // Create a copy to avoid mutating the original array
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
              .map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="callTime">Notes</label>
          <textarea
            rows="4"
            style={{ width: "100%" }}
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
        <div style={{ display: "flex", paddingBottom: "15px" }}>
          <input
            type="checkbox"
            id="terms"
            name="acceptTerms"
            checked={formData.acceptTerms}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="terms"
            style={{ marginLeft: "8px", color: "#c1dadf", marginTop: "20px" }}
          >
            I authorise Loan with Offer to call/SMS/email me about its products
            & have accepted the &nbsp;
            <a href="#" onClick={showModal} style={{ color: "white" }}>
              Terms and Conditions & Privacy Policy
            </a>
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
      <Modal
        title="Terms and Conditions"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row gutter={[16, 16]}>
            <Col>
              <Button onClick={handleCancel}>Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handleOk}>
                OK
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <p>
          To avail the offer specifically given to you,you must pay 1 month EMI
          or pre emi after that you are eligible for the offer,
        </p>
        <p>
          ( Once you pay one month EMI or pre EMI send us mail
          (service@loanwithoffer.com) with your name,loan type , loan account
          number, loan amount, loan disbursement date, bank name, branch name)
        </p>
        <p>
          You can avail the offer by cash( bank transfer only) or amazon gift
          card equivalent to the offer value
        </p>
        <p>
          Please Ensure that your loan was logged in the name of Loan With Offer
          on the preferred bank, so kindly check with your loan processing
          person before login
        </p>
        <p>No offer for cibil issue customers,</p>
        <p>Offer only for selected banks and selected loans </p>
        <p>
          Before submitting the form kindly read the offer section carefully,
        </p>
        <h3>Privacy Policy</h3>
        <p>
          I authorize Loanwithoffer to securely store & use my data (including
          credit infromation report, website behaviour through cookies,
          personal/employment related information etc., to share my data with
          banks/NBFCs/business partners, agents and other third parties, for
          marketing purposes/offering/cross-selling various other products) to
          determine my eligibility & enable me to apply for various product &
          service in future.
        </p>
        <p>
          For inquiries, contact us at [service@loanwithoffer.com/+917449000445].
        </p>
        <p> By using our services, you agree to these terms and conditions.</p>
      </Modal>
      {isSuccessMessageVisible && (
        <div className="success-message-container">
          <p className="success-message">
            Our bank partner will contact you shortly.
            <br />
            Thank You!
          </p>
        </div>
      )}
    </div>
  );
}

export default Form;
