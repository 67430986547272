import React, { useEffect, useState } from "react";
import "../Styles/offer.css";
import { db } from "../firebase"; // Ensure the correct path to your firebase.js
import { collection, getDocs } from "firebase/firestore";

function Offer() {
  const [offerData, setOfferData] = useState([]);

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Offer"));
        const data = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setOfferData(data);
      } catch (error) {
        console.error("Error fetching data from Firestore: ", error);
      }
    };

    fetchOffer();
  }, []);

  if (offerData.length === 0) {
    return <div className="offer-section">Loading...</div>;
  }

  const renderSubtitle = (subtitle) => {
    return subtitle.split("__NEWLINE__").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="offer-section">
      <h1 className="offer-heading">Our Latest Offers</h1>
      <div className="offer-list">
        {offerData.map((offer) => (
          <div className="offer-container" key={offer.key}>
            <div className="offer-text">
              <h2 className="offer-title">{offer.title}</h2>
              <p className="offer-description">
                {renderSubtitle(offer.subtitle)}
              </p>
            </div>
            <div className="offer-image">
              <img src={offer.photoURL} alt="Offer" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Offer;
